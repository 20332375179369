@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800;900");

html {
  height: 100%;
}

a {
  -webkit-tap-highlight-color: transparent;
}

body {
  color: white;
  background-color: black;
  margin: 0;
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: "Manrope";
  font-family: "Manrope Black";
}

#root {
  height: 100%;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.slider-container,
.slide,
.slider-list {
  height: 100%;
}

#shadow {
  filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 1));
}

.transition-marker {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, width;
  transition-duration: 500ms;
}

.paging-item {
  opacity: 60%;
}
